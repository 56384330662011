import { AppRoutes } from '@core/enums/routes.enum';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { DestroyBase } from '@core/base/destroy.class';
import { BehaviorSubject, fromEvent, merge, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  map,
  pluck,
  skip,
  switchMap,
  take,
  takeUntil,
  throttleTime,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { ApiHttpService, ApiSingleResult } from '@capturum/api';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import { NotificationState } from '@shared/modules/notification/notification.state';
import { NotificationMessage } from '@shared/modules/notification/base/notification.model';
import { AuthService } from '@capturum/auth';
import { CacheService } from '@core/services/cache.service';

@Injectable({
  providedIn: 'root',
})
export class InactivityService extends DestroyBase {
  private _activityTimer: NodeJS.Timer;
  private _activeInactivityPopup: NotificationMessage;
  private _INTERVAL_TIME = 1000; // milliseconds
  private _ACTIVITY_GRACE_PERIOD = 60; // seconds
  private token: string;
  private timeToLiveLeft: number;

  constructor(
    private readonly authService: AuthService,
    private readonly cacheService: CacheService,
    private readonly translateService: TranslateService,
    private readonly notificationService: NotificationService,
    private readonly notificationState: NotificationState,
    private readonly apiHttpService: ApiHttpService,
    private router: Router,
  ) {
    super();
  }

  private _userIsActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true); // tslint:disable-line

  public get userIsActive(): boolean {
    return this._userIsActive.getValue();
  }

  /**
   * This method will start a timer and log user activity. When the user is inactive, it will force logout and
   * invalidate the current token. When the user stays active, it will silently keep refreshing the token in the
   * background and start a new timer.
   */
  public checkActivityStatus(): void {
    this._userIsActive
      .asObservable()
      .pipe(
        skip(1), // Because it's a BehaviorSubject and we don't need a default value, but we do want to use getValue()
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((isActive) => {
        if (!isActive && !this._activeInactivityPopup) {
          this._activeInactivityPopup = this.onTriggerInactivityWarning(this._ACTIVITY_GRACE_PERIOD);
        } else if (isActive && this._activeInactivityPopup) {
          this.notificationState.closeById.next(this._activeInactivityPopup?.id.toString());
          this._activeInactivityPopup = null;
        }
      });

    this._activityTimer = setInterval(() => {
      const token: string = this.authService.getToken();
      let activeToken: any;

      try {
        activeToken = jwt_decode(token); // valid token format
      } catch (error) {
        // invalid token format
        this.logout(false);
        this.cacheService.clearCache();
        this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]).then(() => {
          return location.reload();
        });

        return;
      }

      const epochNow = Math.floor(Date.now() / 1000);
      const epochLastUserActivity = Number(localStorage.getItem('last_activity_at')) || epochNow;
      const timeToLive = activeToken.exp - activeToken.nbf; // 900s => 15 minutes
      const timeToToast = timeToLive - this._ACTIVITY_GRACE_PERIOD; // 840s from 15 minutes
      const timeSinceActivity = epochNow - epochLastUserActivity;

      // time left from 15 minutes ( + grace period to kill session before token will expire )
      this.timeToLiveLeft = activeToken.exp - epochNow - this._ACTIVITY_GRACE_PERIOD;

      // Force logout because user was inactive too long (and invalidate active token)
      if (!this.userIsActive && timeSinceActivity >= timeToLive) {
        // inactive 900s
        this.logout();
      } else {
        // User is entering grace period, ask if they want to prevent upcoming logout
        if (this.userIsActive && timeSinceActivity >= timeToToast) {
          // inactive for 840s => 14 min
          this._userIsActive.next(false);
        }

        const tokenWillExpireSoon: boolean = this._ACTIVITY_GRACE_PERIOD >= this.timeToLiveLeft;

        if (tokenWillExpireSoon) {
          // refresh token on each 13 minutes
          this.refreshToken(true);
        }
      }

      // check if token was updated
      // when we have multiple pages open in the same time but just one is active
      if (this.token && this.token !== token && this._activeInactivityPopup) {
        this._userIsActive.next(true); // token was updated
      }

      this.token = token;
    }, this._INTERVAL_TIME);
  }

  public logUserActivity(): void {
    of(null)
      .pipe(
        switchMap(() => {
          return merge(fromEvent(document, 'click'), fromEvent(document, 'keydown'), fromEvent(document, 'touchstart'));
        }),
        throttleTime(this._INTERVAL_TIME), // No need to broadcast more often than the interval runs
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.setLastActivityInStorage();

        this._userIsActive.next(true);
      });
  }

  public setLastActivityInStorage(): void {
    const epochNow = Math.floor(Date.now() / 1000);

    localStorage.setItem('last_activity_at', epochNow.toString(10));
  }

  public onTriggerInactivityWarning(timeLeft: number): NotificationMessage {
    return this.notificationService.cta(
      this.translateService.instant('dxp.core.inactive-warning.title'),
      this.translateService.instant('dxp.core.inactive-warning.description'),
      null,
      timeLeft * 1000,
      {
        label: this.translateService.stream('dxp.core.inactive-warning.cta'),
        closeAfterCallback: true,
        callback: () => {
          return this.setLastActivityInStorage();
        },
      },
    );
  }

  private refreshToken(doubleRequest?: boolean, ms?: number): void {
    // Clear current interval
    clearInterval(this._activityTimer);

    this.onRefreshToken(ms).subscribe((token: string) => {
      if (token) {
        localStorage.setItem('token', token);
        this.checkActivityStatus(); // Start a new interval
      } else if (doubleRequest) {
        this.refreshToken(false, 5000); // refresh token in 5s
      }
    });
  }

  private onRefreshToken(ms = 0): Observable<unknown> {
    return this.apiHttpService.get('/refresh-token/').pipe(
      take(1),
      delay(ms),
      catchError((error) => {
        this.cacheService.forceLogout();

        return throwError(error);
      }),
      map((response: ApiSingleResult<string>) => {
        return response?.data;
      }),
      pluck('token'),
    );
  }

  private logout(force = true): void {
    clearInterval(this._activityTimer); // Don't need this timer anymore
    this.destroy$.next(true); // Don't need userIsActive subscription anymore

    if (force) {
      this.cacheService.forceLogout();
    }
  }
}
